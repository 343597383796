/* Math Editor Styles */
.math-editor {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.math-editor-modal {
  background-color: white;
  margin: auto;
  width: 90%;
  max-width: 900px;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  font-family: inherit;
}

.math-editor-modal > .input-container {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
  max-height: 500px;
}

.math-editor-modal > .input-container > textarea {
  height: 250px;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.math-editor-modal > .input-container > .preview {
  height: 250px;
  overflow-y: auto;
  word-wrap: break-word;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
}
.buttons > button {
  width: 30%;
  background-color: #5ce0d2;
  font-weight: 600;
  color: #212121;
  border: transparent;
  padding: 6px;
  border-radius: 5px;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.toolbar button {
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: #5ce0d2; /*#007bff*/
  color: #212121;
  font-weight: bold !important;
  border-radius: 5px;
  transition: 0.2s ease-in;
  min-width: 40px;
  min-height: 40px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
}
.toolbar button:hover {
  transform: scale(0.9);
}

.toolbar button:hover {
  opacity: 0.7;
}



.preview {
  font-family: system-ui;
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 50px;
  background: #f9f9f9;
  border-radius: 5px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-footer button {
  padding: 8px 12px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.save-button {
  background: #28a745;
  color: white;
}

.save-button:hover {
  background: #218838;
}

.close-button {
  background: #dc3545;
  color: white;
}

.close-button:hover {
  background: #c82333;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;1,200&display=swap");
* {
  font-family: "Poppins", sans-serif !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

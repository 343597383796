@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper{
  display: flex;
  /* background-color: rgba(26, 25, 25, 0.199); */
  
}
* {
  font-family: "Poppins", sans-serif !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Math Editor Styles */
.math-editor {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.math-editor-modal {
  background-color: white;
  margin: auto;
  width: 90%;
  max-width: 900px;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  font-family: inherit;
}

.math-editor-modal > .input-container {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px;
  gap: 20px;
  max-height: 500px;
}

.math-editor-modal > .input-container > textarea {
  height: 250px;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.math-editor-modal > .input-container > .preview {
  height: 250px;
  overflow-y: auto;
  word-wrap: break-word;
}

.buttons {
  width: 100%;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
}
.buttons > button {
  width: 30%;
  background-color: #5ce0d2;
  font-weight: 600;
  color: #212121;
  border: transparent;
  padding: 6px;
  border-radius: 5px;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
}

.toolbar button {
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: #5ce0d2; /*#007bff*/
  color: #212121;
  font-weight: bold !important;
  border-radius: 5px;
  transition: 0.2s ease-in;
  min-width: 40px;
  min-height: 40px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
}
.toolbar button:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.toolbar button:hover {
  opacity: 0.7;
}



.preview {
  font-family: system-ui;
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 50px;
  background: #f9f9f9;
  border-radius: 5px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-footer button {
  padding: 8px 12px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.save-button {
  background: #28a745;
  color: white;
}

.save-button:hover {
  background: #218838;
}

.close-button {
  background: #dc3545;
  color: white;
}

.close-button:hover {
  background: #c82333;
}

.react-contextmenu-wrapper{
    height:100%;
}
.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 100px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }
  .react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
  cursor: pointer;
}
.react-contextmenu-item{
    margin-top: "20px";
}


.module-search {
  position: relative;
}

.module-search > .search-list {
  position: absolute;
  overflow-y: auto;
  right: 0;
  -webkit-transform:translateY(55%);
          transform:translateY(55%);
  width:400px;
  max-height:550px;
  background-color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.search-list > ul {
  width: 100%;
  padding: 0;
  margin: 0 0 auto 0;
}
.search-list > ul > li > a{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:2.5% 5%;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin:0;
  align-items:normal !important;
  border-bottom: 1px solid #c4c4c4;
}
.search-list > ul > li:hover > a {
  background-color: #f4f4f4;
  cursor: pointer;
}
.search-list > ul > li > a > h5 {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin:0;
}
.search-list > ul > li > a > p {
  display: flex;
  flex-wrap: wrap;
  font-size:0.8rem;
  margin-top:6px;
  margin-bottom:0px;
}
.search-list > ul > li > a > p > span {
  display: block;
  margin-right:2px;
}

.search-list > ul > li > a> p > span:nth-child(even) {
  font-weight: 600;
  color: black
}



.load-more {
  padding:5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #f1f1f1;
}
.file {
  background-color: white;
  margin: 5px 0;
}

.file-upload-error {
  color: orangered;
  font-weight: lighter;
}

.file-upload-info {
  color: royalblue; 
  font-weight: lighter;
}

.file > span {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: capitalize;
}

.file > .file-details {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9;
}

.file > .success {
  background-color: green;
  color: white;
}

.file > .file-details > p {
  margin: 0;
}

